import request from '@/network/request.js'
/**
 * 获取来客商品
 */
export function lists_lk_merchandise(data) {
	return request({
		url: '/admin/tik_tok_lk_merchandise/merchandise_list',
		method: 'post',
		data,
	})
}

